<template>
  <div class="container-page">
    <template>
      <v-card class="transparent elevation-0 card-pt-0">
        <v-card-title>
          <v-spacer />
          <v-flex
            xs12
            sm4>
            <v-text-field
              v-model="pagination.search"
              label="Buscar por boletos"
              :prepend-icon="$root.icons.layout.search"
              :append-icon="pagination.search.length > 0 ? 'close' : ''"
              single-line
              hide-details
              @click:append="clearSearch" />
          </v-flex>
        </v-card-title>
      </v-card>
      <loading
        v-if="!isRender && loading"
        class="absolute-center" />
      <div
        v-if="isRender"
        class="px-0 py-0">
        <v-card
          v-if="bankSlipReturnFiles.totalEntries > 0"
          class="transparent elevation-0 card-pt-0">
          <vue-dropzone
            id="drop-files"
            ref="dropFiles"
            class="accent--text"
            :options="dropzoneOptions"
            @vdropzone-sending="sendingFile"
            @vdropzone-success="uploadSuccess" />
          <v-data-table
            class="transparent table-clicked table-sticky"
            :footer-props="footerProps"
            :loading="loading === 1"
            :options.sync="pagination"
            :headers="headers"
            :items="bankSlipReturnFiles.entries"
            :search="pagination.search"
            :server-items-length="bankSlipReturnFiles.totalEntries">
            <template
              v-slot:item="props"
              class="transparent">
              <tr tabindex="0">
                <td>{{ props.item.fileName }}</td>
                <td>{{ props.item.fileSize }}</td>
                <td>{{ props.item.totalRegisters }}</td>
                <td>{{ props.item.generatedAt }}</td>
                <td>{{ $t('bankSlipReturnFile.state.' + props.item.state) }}</td>
              </tr>
            </template>
            <template v-slot:pageText="props">
              Exibindo de {{ props.pageStart }} a {{ props.pageStop }} no total de {{ props.itemsLength }}
            </template>
            <empty-state
              slot="no-results"
              class="mt-2"
              size="75px"
              :icon="$root.icons.state.no_result">
              Sua busca por "{{ pagination.search }}" não possui resultados
            </empty-state>
          </v-data-table>
        </v-card>
        <div v-else>
          <empty-state
            v-if="pagination.state !== '' || pagination.search !== ''"
            size="75px"
            :icon="$root.icons.link.bankSlipReturnFiles"
            class="absolute-center">
            Até o momento, não há boletos com esse resultado
          </empty-state>
          <vue-dropzone
            v-else-if="isRender && !loading"
            id="drop-empty"
            ref="dropFiles"
            :options="dropzoneOptions"
            @vdropzone-sending="sendingFile"
            @vdropzone-success="uploadSuccess" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Loading from '@/components/ui/Loading'
  import EmptyState from '@/components/ui/EmptyState'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import vueDropzone from 'vue2-dropzone'
  import md5 from 'js-md5'
  import { mapGetters } from 'vuex'

  export default {
    components: { Loading, EmptyState, vueDropzone },
    data () {
      const rowsPerPageItems = [13, 25, 50, 100]

      return {
        footerProps: {
          rowsPerPageItems: rowsPerPageItems,
          rowsPerPageText: 'Mostrar por página'
        },
        loading: 0,
        isRender: false,
        bankSlipReturnFiles: [],
        search: '',
        headers: [
          { text: 'Arquivo', value: 'file_name' },
          { text: 'Tamanho', value: 'file_size', sortable: false },
          { text: 'Total de Registros', value: 'total_registers', sortable: false },
          { text: 'Gerado em', value: 'generated_at', sortable: true },
          { text: 'Status', value: 'state', sortable: true }
        ],
        pagination: {
          state: sessionStorage.getItem('bankSlipReturnFilesPaginationState') || '',
          search: sessionStorage.getItem('bankSlipReturnFilesPaginationSearch') || '',
          sortBy: sessionStorage.getItem('bankSlipReturnFilesPaginationSortBy') || 'generated_at',
          descending: JSON.parse(sessionStorage.getItem('bankSlipReturnFilesPaginationDescending') || true),
          page: parseInt(sessionStorage.getItem('bankSlipReturnFilesPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('bankSlipReturnFilesPaginationRowsPerPage') || 13),
          rowsPerPageItems: rowsPerPageItems
        },
        dropzoneOptions: {
          dictDefaultMessage: '<i aria-hidden="true" class="icon accent--text material-icons" style="font-size: 30px;">file_upload</i> Arraste um arquivo ou  clique nessa área'
        }
      }
    },
    computed: {
      ...mapGetters('global', ['getHostUpload'])
    },
    // TODO Retornar lista de arquivo de retorno
    beforeMount () {
      this.dropzoneOptions.url = this.getHostUpload
      this.dropzoneOptions.headers = { 'Authorization': `Bearer ${this.$store.state.user.user.token}` }
    },
    methods: {
      clearSearch () {
        this.pagination.search = ''
      },
      sendingFile (file, xhr, formData) {
        formData.append('resource', 'BankSlipReturn')
        formData.append('file_size', file.size)
        formData.append('md5', md5(file))
      },
      uploadSuccess (file) {
        this.$refs.dropFiles.removeFile(file)
      }
    }
  }
</script>

<style lang="sass">
  .height-auto
    height: auto!important
    overflow: initial!important
    width: 100%

  .v-align-center.icon
    vertical-align: bottom

  .select-state
    position: relative
    top: 12px

  #drop-empty
    height: calc(100vh - 260px)
    border: none
    position: relative
    background: transparent

    .dz-message
      position: absolute
      top: calc(50% - 50px)
      left: 50%
      transform: translate(-50%, -50%)

      span
        display: flex
        flex-direction: column

        i
          margin-bottom: 5px

  #drop-files
    border: none
    min-height: 20px
    padding: 10px
    position: relative
    z-index: 1

    .dz-message
      margin: 0
      font-size: 16px

    &.dropzone .dz-preview
      margin: 6px

      .dz-image
        opacity: 0

        img
          height: 10px

      .dz-progress
        height: 10px

        .dz-upload
          background-color: $secondary

      .dz-details
        background: $accent
        border: 1px solid $accent
        border-radius: $radiusDefault
        padding: 1em

        .dz-size
          margin-bottom: 1.5em

      .dz-success-mark svg,
      .dz-error-mark
        top: 12px

        svg
          width: 20px
          height: 20px
          float: right
          margin-right: 10px

  .container-page
    min-height: calc(100vh - 193px) !important
</style>
